<template>
  <div>
    <div @click="catVisible = !catVisible" class="gradient-border" id="box">
      <h4>Petition!</h4>
      Restore Whatsapp Profile Picture
      <p class="status">Status: <span class="success">Successful</span> / <span>Expired</span></p>
    </div>

    <img
      v-if="catVisible"
      class="cat"
      src="https://i.gifer.com/UPRm.gif"
      alt=""
    />
  </div>
</template>

<script>
export default {
    name: 'Petition',
    data(){
        return {
            catVisible: false 
        }
    }
};
</script>

<style lang="scss">
.cat {
  height: 170px;
  margin-bottom: 10px;
}
#box {
  justify-content: center;
  color: white;
  margin: 10px auto 30px auto;
  width: 100%;
  font-size: 22px;
  box-shadow: 1px 1px 10px rgba(51, 51, 51, 0.363);
  padding: 20px;

  h4 {
    font-size: 28px;
  }

  span {
    font-weight: bold;
    display: block;
  }

  .please {
    display: flex;
    font-size: 16px;
    span {
      font-weight: 400;
      opacity: 0.5;
    }
  }

  .disc {
    font-size: 12px;
    opacity: 0.2;
    margin-top: 10px;
  }

  .status {
    display: flex;

    span {
      margin-left: 8px;
      color: #c6c6c6;
    }

    .success {
      margin-right: 8px;
      color: #7ae896;
    }
  }
}

.gradient-border {
  --borderWidth: 8px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);
  box-shadow: 1px 1px 10px rgba(51, 51, 51, 0.733);
}
.gradient-border:after {
  box-shadow: 1px 1px 10px rgba(51, 51, 51, 0.363);
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>