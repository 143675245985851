<template>
  <div class="container">
    <Petition/>
    <h2>To the best tooth extractor ever</h2>
    <p class="name">Aasiyah A. Shaikh</p>
    <img src="https://i.gifer.com/F9a.gif" alt="">
    <p class="desc">Made this to list down songs that I think you might like? But most probably you won't. 😪</p>

    <div class="list">
        <h3>1. Songs that remind me of you</h3>
        <p>Please don't judge, but I don't think there's any other human out there I think of when listening to these.</p>
        <img src="https://media.tenor.com/images/7a7a80211ca6b3cf44068917b0379f65/tenor.gif" alt="">
        <a target="_blank" :href="song.link" class="song" v-for="(song, index) in listOne" :key="index">
            <h4>{{song.name}}</h4>
            <p class="artist">{{song.artist}}</p>
            <p class="year">{{song.movie}} | {{song.year}}</p>
        </a>
        <p style="margin-top: 10px">This has to be there</p>
        <a target="_blank" href="https://www.youtube.com/watch?v=2wXDRtswljs" class="song">
            <h4>Pehla Nasha</h4>
            <p class="artist">No clue</p>
            <p class="year">Jo Jeeta Wohi Sikander | 1992</p>
        </a>
    </div>

    <div class="list">
        <h3>2. When you're feeling low</h3>
        <p>Usecases: Moments when somebody doesn't let you extract their tooth? When you faint and later feel bad about it? When you don't feel like studying?</p>
        <img src="https://i.pinimg.com/originals/c7/df/ca/c7dfca55fa89585a5cbbfef17405a02e.gif" alt="">
        <p class="small-desc"><b>PS:</b> Remember when you were not sure if you'd be able to extract? But you did it. When you were not sure if you'd be able to ride a cycle but you still did it. Wait who am I trying to motivate, you cleared NEET!! </p>
        <a target="_blank" :href="song.link" class="song" v-for="(song, index) in listTwo" :key="index">
            <h4>{{song.name}}</h4>
            <p class="artist">{{song.artist}}</p>
            <p class="year">{{song.movie}} | {{song.year}}</p>
        </a>
    </div>

    <div class="list">
        <h3>3. Peace of mind 🍂</h3>
        <p>Not sure how to explain this, but these are a few ones that are really close to me? And I thought of sharing them with you</p>
        <img src="https://i.pinimg.com/originals/93/73/d2/9373d27530caf8bd78c6adfa13c1b362.gif" alt="">
        <a target="_blank" :href="song.link" class="song" v-for="(song, index) in listThree" :key="index">
            <h4>{{song.name}}</h4>
            <p class="artist">{{song.artist}}</p>
            <p class="year">{{song.movie}} | {{song.year}}</p>
        </a>
    </div>

    <div class="list">
        <h3>4. Just some more good music 🍂</h3>
        <p>Songs I think everyone should listen once to</p>
        <img class="gif" src="https://i.pinimg.com/originals/2e/c9/57/2ec9576d60ab2f223b5612a94b80c9cd.gif" alt="">
        <a target="_blank" :href="song.link" class="song" v-for="(song, index) in listFour" :key="index">
            <h4>{{song.name}}</h4>
        </a>
    </div>

    <div class="end">
        <p>Thank you for being a part of our lives. 'Our' lives, because I am sure even others are so glad to have you but are just shy to tell you. You've been an inspiration throughout and I am sure you will continue to inspire me and others. Aasiyah, you have my heart</p>
        <p>okaaaayyyyy</p>
        <p>before I start typing</p>
        <p>more cringy things</p>
        <p>k bye</p>
        <br>
        <img src="https://media.tenor.com/images/61c2fdea27fd50d2b160e003fb3e6d97/tenor.gif" alt="">
        <br>
        <p>Made with 💗</p>
        
    </div>
  </div>
</template>

<script>
import Petition from "./Petition";

export default {
    name: 'Main',
    data(){
        return {
            listOne: [
                {
                    name: 'Maula Mere Maula',
                    artist: 'Roopkumar Rathod',
                    movie: 'Anwar',
                    year: 2006,
                    link: 'https://www.youtube.com/watch?v=mRqB4ryQ6QA'
                },
                {
                    name: 'All You Need To Know ✨',
                    artist: 'Gryffin & Slander',
                    movie: 'Gravity',
                    year: 2019,
                    link: 'https://www.youtube.com/watch?v=GLUEnI7NEUI'
                },
                {
                    name: 'Ankhon Mein Teri',
                    artist: 'KK',
                    movie: 'Om Shanti Om',
                    year: 2007,
                    link: 'https://www.youtube.com/watch?v=miEmOaBvvrQ'
                },
                {
                    name: 'Choo Lo',
                    artist: 'The Local Train',
                    movie: 'Aalas Ka Pedh',
                    year: 2016,
                    link: 'https://www.youtube.com/watch?v=sFMRqxCexDk'
                },
                {
                    name: 'No Words',
                    artist: 'The Script',
                    movie: '#3',
                    year: 2012,
                    link: 'https://www.youtube.com/watch?v=L42I6FE_bt4'
                },
                {
                    name: 'Kuch Is Tarah',
                    artist: 'Atif Aslam',
                    movie: 'Doorie',
                    year: 2005,
                    link: 'https://www.youtube.com/watch?v=2K6HADNmESg'
                },
                {
                    name: 'Dil Ibaadat',
                    artist: 'KK',
                    movie: 'Jannat',
                    year: 2009,
                    link: 'https://www.youtube.com/watch?v=ex5H4XgMhRI'
                },
                {
                    name: `I Don't Wanna Miss a Thing 💙`,
                    artist: 'Aerosmith',
                    movie: `I Don't Want to Miss a Thing`,
                    year: 1998,
                    link: 'https://www.youtube.com/watch?v=Ss0kFNUP4P4&pbjreload=101'
                },
                {
                    name: 'Main Agar Kahoon',
                    artist: 'Sonu Nigam',
                    movie: 'Om Shanti Om',
                    year: 2007,
                    link: 'https://www.youtube.com/watch?v=_jBpcxGOM_4'
                },
                {
                    name: 'Wonderwall (You are my wonderwall :) )',
                    artist: 'Oasis',
                    movie: '(Whats the Story) Morning Glory?',
                    year: 1995,
                    link: 'https://www.youtube.com/watch?v=sYffFEIAzdE'
                },
            ],

            listTwo: [
                {
                    name: 'Whatever It Takes',
                    artist: 'Imagine Dragons',
                    movie: 'Evolve',
                    year: 2017,
                    link: 'https://www.youtube.com/watch?v=M66U_DuMCS8'
                },
                {
                    name: 'Run for Your Life',
                    artist: 'The Seige',
                    movie: 'Run For Your Life (Clean)',
                    year: 2018,
                    link: 'https://www.youtube.com/watch?v=wDZOmXR2sxw'
                },
                {
                    name: 'Superheroes',
                    artist: 'The Script',
                    movie: 'Voices',
                    year: 2014,
                    link: 'https://www.youtube.com/watch?v=IowZqZHJ-cA'
                },
                {
                    name: 'HEROES',
                    artist: 'ZAYDE WOLF',
                    movie: 'Rare Breed',
                    year: 2016,
                    link: 'https://www.youtube.com/watch?v=jeTTeLTzDEQ'
                },
                {
                    name: 'Legend Never Die',
                    artist: 'Pentakill',
                    movie: 'Legends Never Die',
                    year: 2017,
                    link: 'https://www.youtube.com/watch?v=zQo5pxMDV0Y'
                },
                {
                    name: 'Written in the stars',
                    artist: 'Tinie Tempah',
                    movie: 'Disc-Overy',
                    year: 2010,
                    link: 'https://www.youtube.com/watch?v=zquxCqDMY2M'
                },
                {
                    name: 'Sinbad The Sailor',
                    artist: 'Farhan Akhtar, Raman Mahadevan',
                    movie: 'Rock On',
                    year: 2008,
                    link: 'https://www.youtube.com/watch?v=x0HzGDBR88g'
                },
            ],

            listThree: [
                {
                    name: 'Arziyan',
                    artist: 'Javed Ali, Kailash Kher',
                    movie: 'Delhi-6',
                    year: 2009,
                    link: 'https://www.youtube.com/watch?v=de7o07KLw1E'
                },
                {
                    name: 'Allahu Akbar',
                    artist: 'Shafqat Amanat Ali Khan & Ahmed Jehanzeb',
                    movie: 'Coke Studio Season 10',
                    year: 2017,
                    link: 'https://www.youtube.com/watch?v=JnjCKHPWq5s'
                },
                {
                    name: 'Tu Kuja Man Kuja',
                    artist: 'Shiraz Uppal & Rafaqat Ali Khan',
                    movie: 'Coke Studio Season 9',
                    year: 2018,
                    link: 'https://www.youtube.com/watch?v=VPp3fudw69w'
                },
                {
                    name: 'Ali Maula',
                    artist: 'Salim-Sulaiman',
                    movie: 'Kurbaan',
                    year: 2017,
                    link: 'https://www.youtube.com/watch?v=wOXiNNWgT_s'
                },
                {
                    name: 'Marhaba Ya Mustafa',
                    artist: 'AR Rahman',
                    movie: 'Al Risalah',
                    year: 2009,
                    link: 'https://www.youtube.com/watch?v=vfg18vpuyoo'
                },
            ],

            listFour: [
                {
                    name: 'Incomplete - Backstreet Boys',
                    link: 'https://www.youtube.com/watch?v=nCzqv0Z5Gjo'
                },
                {
                    name: 'A Sky Full Of Stars - Coldplay',
                    link: 'https://www.youtube.com/watch?v=NDonh28AY3I'
                },
                {
                    name: 'Owl City - Fireflies',
                    link: 'https://www.youtube.com/watch?v=QBgl4rVz3Ks'
                },
                {
                    name: 'Leave Out All The Rest - Linkin Park',
                    link: 'https://www.youtube.com/watch?v=yZIummTz9mM'
                },
                {
                    name: 'Gym Class Heroes: Stereo Hearts',
                    link: 'https://www.youtube.com/watch?v=T3E9Wjbq44E'
                },
                {
                    name: 'Aaoge tum kabhi - The Local Train',
                    link: 'https://www.youtube.com/watch?v=bSx06TFHYo4'
                },
                {
                    name: 'Iris - The Goo Goo Dolls',
                    link: 'https://www.youtube.com/watch?v=aNO6yd66PpA'
                },
                {
                    name: 'Green Day - Wake Me Up When September Ends',
                    link: 'https://www.youtube.com/watch?v=VOJqRknAKVE'
                },
                {
                    name: 'Hoobastank - The Reason',
                    link: 'https://www.youtube.com/watch?v=ZADpco6Zn9I'
                },
                {
                    name: 'Roye Roye - Sahir Ali and Momina Mustehsan',
                    link: 'https://www.youtube.com/watch?v=S33PzzeozpU'
                },
            ]
        }
    },
    components: {
        Petition
    },
    created(){
        if (localStorage.getItem("playlistPassShahid") === null) {
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');
    .container {
        padding: 20px;
        max-width: 800px;
        margin: 0 auto;

        h2 {
            color: rgb(56, 56, 56);
            font-size: 28px;
            line-height: 38px;
            font-weight: 400;
        }

        .name {
            color:  #dc4d4d;
            font-size: 72px;
            font-family: 'Covered By Your Grace', cursive;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 60px;
            margin-top: 10px;
            padding-bottom: 20px;
        }

        .desc {
            margin-top: 20px;
        }

        .list {
            margin-top: 30px;

            h3 {
                color:  #dc4d4d;
                font-size: 24px;
            }

            .song {
                border: 2px solid #dc4d4d;
                padding: 10px 20px;
                margin-top: 10px;
                border-radius: 10px;
                display: block;
                text-decoration: none;
                color: #222;
                background-color: white;

                .artist{
                    font-size: 14px;
                    opacity: .8;
                    color: #222;
                }

                .year {
                    font-size: 12px;
                    opacity: .8;
                }
            }

                            .gif{
                    max-width: 300px;
                }

            .small-desc{
                margin-top: 10px;
                font-size: 14px;
            }
        }

        .end {
            border-top: 2px solid rgb(223, 223, 223);
            margin-top: 30px;
            padding-top: 20px;
        }
    }
</style>