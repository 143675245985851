<template>
  <div class="birthday container">

    <p class="intro">I would like to start by telling you a little story.. </p>

    <img class="my-20 img" src="../assets/image2.png" alt="">
    <h2 style="font-weight: bold">**The Pedo Dentist**</h2>

    <h3 style="font-size: 36px; margin-bottom: 0px; border-top: 1px solid #e0e0e0; padding-top: 10px; margin-top: 20px">
      Chapter 1.</h3>

    <img class="my-20 img" src="../assets/image1.png" alt="">

    <p class="line">A girl from the mysterious lands of Qatar, arrived in Mumbai to pursue her dreams <em
        style="opacity: .6">(More like her
      parents moved here but we will stick to this for effect)</em>.. her name you ask?<br><span class="cursive"
                                                                                                 style="font-size: 28px">Aasiyah Altaf Shaikh</span>
    </p>

    <img class="mt-30 img" src="../assets/image3.png" alt="">

    <p class="line">With dreams in her eyes</p>

    <img class="mt-30 img" src="../assets/image4.png" alt="">

    <p class="line">and food in her mouth</p>

    <img class="mt-30 img" src="../assets/image5.png" alt="">

    <p class="line">she set forth to become the best dentist <strong>EVER.</strong></p>

    <h3 style="font-size: 36px; margin-bottom: 0px; border-top: 1px solid #e0e0e0; padding-top: 10px; margin-top: 20px">
      Chapter 2.</h3>

    <img class="mt-30 img" src="../assets/image6.png" alt="">

    <p class="line">The journey wasn't easy</p>

    <img class="mt-30 img" src="../assets/image10.png" alt="">

    <p class="line">There was a time when she was not sure about tooth extractions 😥</p>

    <img class="mt-30 img" src="../assets/image11.png" alt="">

    <p class="line">She even questioned herself if she'd ever be able to drive?</p>

    <img class="mt-30 img" src="../assets/image8.png" alt="">

    <p class="line">But with constant support from her parents, tiny little sisters and her beautiful cat ...</p>

    <img class="mt-30 img" src="../assets/image12.png" alt="">

    <p class="line">🎉She not only overcame her fear of extractions, in fact she even made her patient a new set of <a
        href="https://en.wikipedia.org/wiki/Dental_prosthesis">Prosthetic Teeth</a>🎉 <strong>FOR FREE!</strong></p>

    <!--    <img class="mt-30 img" src="../assets/image12.png" alt="">-->

    <p class="line" style="margin: 40px 0 20px 0">And what about her driving?</p>

    <img class="mt-30 img" src="../assets/image13.png" alt="">

    <p class="line">🎉 Let them lift the lockdown, you'll find her drifting through the streets🎉</p>

    <img class="mt-30" style="max-width: 90%" src="../assets/image14.png" alt="">

    <p class="line">PS. Here's a lil glimpse of it</p>

    <h3 style="font-size: 36px; margin-bottom: 0px; border-top: 1px solid #e0e0e0; padding-top: 10px; margin-top: 20px">
      Chapter 3.</h3>

    <img class="mt-30 img" src="../assets/image16.png" alt="">

    <p class="line">She's like a mini <a href="https://en.wikipedia.org/wiki/John_Wick_(film)">John Wick</a></p>

    <img class="mt-30 img" src="../assets/image17.png" alt="">

    <p class="line">She's a woman of <strong>FOCUS</strong></p>

    <img class="mt-30 img" src="../assets/image18.png" alt="">

    <p class="line"><strong>COMMITMENT</strong></p>

    <img class="mt-30 img" src="../assets/image19.png" alt="">

    <p class="line">And sheer fking <strong>WILL</strong></p>
    <a style="font-size: 12px" href="https://www.youtube.com/watch?v=yp0X_4Jz5QE">reference (1:40)</a>

    <br>

    <img class="mt-30 img" src="../assets/image15.png" alt="">

    <p class="line">But she comes with her own set of flaws</p>

    <img class="mt-30 img" src="../assets/image20.png" alt="">

    <p class="line">Like she doesn't know how to click good pictures of other people (please, at least 1? someday? please?)</p>

    <img class="mt-30 img" src="../assets/image21.png" alt="">

    <p class="line">Or the fact that she just forgets things that you told her last week?</p>

    <p class="line" style="margin: 40px 0 20px 0"><strong>BUT</strong></p>

    <img class="mt-30 img" src="../assets/image22.png" alt="">

    <p class="line">She's empathetic</p>

    <img class="mt-30 img" src="../assets/image27.png" alt="">

    <p class="line">Self-disciplined and Hardworking </p>

    <img class="mt-30 img" src="../assets/image28.png" alt="">

    <p class="line">Generous and kind</p>

    <img class="mt-30 img" src="../assets/image24.png" alt="">

    <p class="line"><strike>sometimes</strike> weird</p>

    <img class="mt-30 img" src="../assets/image29.png" alt="">

    <p class="line">and adventurous</p>

    <img class="mt-30 img" src="../assets/gif1.gif" alt="">

    <p class="line">Witty and Humorous (though her sense of humour has significantly improved since past 6 months) 😎</p>

    <img class="mt-30 img" src="../assets/image23.png" alt="">

    <p class="line">the supportive friend we all asked for</p>

    <img class="mt-30 img" src="../assets/image25.png" alt="">

    <p class="line">but most importantly someone who would go the distance for the people she cares about 👧👦👧</p>

    <h3 style="font-size: 36px; margin-bottom: 0px; border-top: 1px solid #e0e0e0; padding-top: 10px; margin-top: 20px">
      The Epilogue</h3>

    <img class="mt-30 img" src="../assets/image26.png" alt="">

    <p class="line">This was just a glimpse of what this girl is all about, she's so much more. </p>
    <br>
    <p class="line">Aasiyah, you've been a huge inspiration, have said it a 100 times and I still mean it</p>
    <br>
    <p class="line">I genuinely hope and pray that Allah SWT blesses you with love, happiness, success, taqwa and your
      goal to practice abroad *cough_mac_cough_book*</p>
    <br>
    <p class="line">I'll have to stop writing now, I don't want to run out of words when I wish you via text! *just
      millennial things* </p>
    <p class="line my-20"><em>there's more</em></p>
    <hr>
    <div class="" v-if="!final">
      <p class="line">I did plan something better than this (trust me, I did 😢) but our planet had other plans ..
        before you go just click on this cake, you'll hear a song</p>

      <img @click="finalStuff" src="../assets/cake.png" style="margin: 20px auto; height: 90px" alt="">
    </div>

    <div class="final" v-if="final">
      <h2 class="heading">Happy Birthday</h2>
      <p class="subtext">to our little dentist <img src="../assets/seal.png" alt=""></p>
      <img class="header" src="../assets/birthday1.gif" alt="">
      <p style="font-size: 14px; margin-top: 20px">you really thought I'd let you listen to music during Ramadan?
        Astaghfirullah! Go watch this video</p>
      <a style="font-size: 14px; margin-top: 20px" href="https://www.youtube.com/watch?v=NPUw8AIU9qo">Is Music
        Permissible Or Forbidden by Mufti Menk
      </a>
    </div>

    <br>
    <p style="color: #dc4d4d; margin-top: 20px">❤</p>
    <p style="font-size: 12px">-- with lots of love --</p>
    <br>
    <p style="font-size: 12px">kbye</p>
    <br>
  </div>
</template>
<script>

export default {
  name: 'Birthday',
  data() {
    return {
      final: false
    }
  },
  methods: {
    finalStuff() {
      this.final = true;
      this.$confetti.start();
      this.love();
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    },
    love() {
      this.$confetti.update({
        particles: [
          {
            type: 'heart',
          },
        ],
        dropRate: 10,
        defaultColors: [
          'red',
          'lightsalmon',
          'yellowgreen',
          'skyblue'
        ],
      });
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.cursive {
  font-size: 20px;
  font-family: 'Nanum Pen Script', cursive;
}

.birthday {
  text-align: center;

  .heading {
    font-family: 'Nanum Pen Script', cursive;
    text-align: center;
    font-size: 56px;
    color: #07b39b;
  }

  .subtext {
    text-align: center;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-top: -8px;
      margin-left: 8px;
      height: 28px;
    }
  }

  .img {
    height: 253px;
    width: auto;
  }

  .header {
    display: block;
    margin-top: 20px;
    width: 100%;
    margin-top: -5px;
  }

  .intro {
    font-size: 26px;
  }

  hr {
    margin: 20px 0;
    opacity: .2;
  }

  .my-20 {
    margin: 20px 0;
  }

  .mt-30 {
    margin-top: 30px;
  }

  button {
    border: none;
    outline: none;
    background-color: #dc4d4d;
    color: white;
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 16px;
    margin-top: 10px;
  }

  .final {
    margin-top: 20px;
  }
}
</style>