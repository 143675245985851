<template>
  <div class="home">
    <img src="https://i.pinimg.com/originals/5b/8b/d8/5b8bd869fb9e04be0c2a7f6202664408.gif" alt="">
    <input type="password" placeholder="password" v-model="password">
    <button @click="redirectToMain">Let me through</button>
    <p v-if="wrongPassword" class="error">Wrong password you dumbass!</p>
    <p>If you don't have the password, ask yourself, do you deserve one?</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return {
      password :'',
      wrongPassword: false
    }
  },
  methods: {
    redirectToMain(){
      if(this.password === 'Aasiyah03598') {
        this.$router.push({ name: 'Birthday' });
        localStorage.setItem('playlistPassShahid', true);
      } else {
        this.wrongPassword = true;
      }
    }
  }
}
</script>
<style lang="scss">
.home{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;

  img{
    height: 150px;
    display: block;
  }

  input{
    margin-top: 20px;
    display: block;
    padding: 10px;
    border: 0;
    border: 1px solid rgb(48, 56, 65);
    border-radius: 10px;
  }

  button{
    margin-top: 10px;
    padding: 10px;
    background-color: rgb(233, 140, 112);
    outline: 0;
    border: 0;
    border-radius: 50px;
    color: white;
    cursor: pointer;

    &:hover{
      background-color: rgb(226, 128, 97);
    }
  }

  p{
    font-size: 10px;
    margin-top: 18px;
    opacity: .2;
  }

  .error {
     font-size: 14px;
    opacity: 1;
    color: rgb(223, 81, 81);
  }
}
</style>
